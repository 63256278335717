function ChatGoBackToReply(props) {
    const {
      returnMessageId,
      returnMessageReplied,
      lang,
    } = props;
  
    return (
      <>
      {
        returnMessageId && (
            <div
            style={{
              backgroundColor: "rgb(70 70 70 / 70%)",
              color: "white",
              width: 200,
              height: 36,
              borderRadius: 6,
              display: "flex",
              margin: "0 auto 10px auto",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              returnMessageReplied();
            }}
          >
             {<i class="icon-return" style={{ fontSize: '24px' }}></i>}
            <span>
              {lang["__go_back_to_reply"]}
            </span>
          </div>
        )
      }
      </>
    );
  }
  